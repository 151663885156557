<template>
  <li class="relative">
    <button
      class="align-middle rounded-full focus:shadow-outline-purple focus:outline-none"
      @click="toggleProfileMenu"
      @keydown.escape="closeProfileMenu"
      aria-label="Account"
      aria-haspopup="true"
    >
      <i class="icon-user-circle-o fa-2x object-cover w-8 h-8 rounded-full"></i>
      <!-- <img
                class="object-cover w-8 h-8 rounded-full"
                src="https://images.unsplash.com/photo-1502378735452-bc7d86632805?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&s=aa3a807e1bbdfd4364d1f449eaa96d82"
                alt=""
                aria-hidden="true"
            /> -->
    </button>
    <template v-if="isProfileMenuOpen">
      <ul
        x-transition:leave="transition ease-in duration-150"
        x-transition:leave-start="opacity-100"
        x-transition:leave-end="opacity-0"
        @click="closeProfileMenu"
        @keydown.escape="closeProfileMenu"
        class="absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:border-gray-700 dark:text-gray-300 dark:bg-gray-700"
        aria-label="submenu"
      >
        <li class="flex">
          <p
            class="inline-flex items-center w-full px-1 py-1 text-md font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200 no-underline"
          >
            <i class="icon-street-view mr-1 mb-1"></i>
            {{ user.fullName }}
          </p>
        </li>
        <!-- <li class="flex">
          <a
            class="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
            href="#"
          >
            <svg
              class="w-4 h-4 mr-3"
              aria-hidden="true"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              ></path>
            </svg>
            <span>Profile</span>
          </a>
        </li> -->
        <li class="flex">
          <a
            class="inline-flex items-center w-full px-1 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
            href="#"
          >
            <i class="icon-sliders mr-2"></i>
            <span>Settings</span>
          </a>
        </li>
        <li class="flex">
          <a
            class="cursor-pointer inline-flex items-center w-full px-1 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
            @click.prevent="logOut"
          >
            <i class="icon-logout mr-2"></i>
            <span>Log out</span>
          </a>
        </li>
      </ul>
    </template>
  </li>
</template>

<script>
import Axios from "axios";
import { mapState } from "vuex";

export default {
  name: "MenuProfile",
  props: {},
  data() {
    return {
      isProfileMenuOpen: false,
    };
  },
  computed: {
    ...mapState(["access_token", "user"]),
  },
  mounted() {
    this.notificationsWindows();
  },
  methods: {
    notificationsWindows(){
      if ('Notification' in window) {
        Notification.requestPermission()
      }
    },
    async logOut() {
      const URL = `${process.env.VUE_APP_URL}user-logout`;

      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token.replace(/['"]+/g, "")}`,
        },
      };
      let res = await Axios.get(URL, fetchConfig);

      if (res.statusText === "OK") {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        localStorage.removeItem("year");
        localStorage.removeItem("accTemp");
        localStorage.removeItem("accTransResul");
        // this.$router.push({ path: '/login' })
        // solo hago un reload y no cargo la ruta de la pagina.. para evitar que login se cargue dentro de <router-view/> de App.vue
        location.reload();
      }
      if (Notification.permission === 'granted') {
        new Notification('Sesión Finalizada', {
          body: 'Su sesión se ha cerrado correctamente',
          //icon: 'ruta/de/la/imagen.png',
        })}
    },
    toggleProfileMenu() {
      this.isProfileMenuOpen = !this.isProfileMenuOpen;
    },
    closeProfileMenu() {
      this.isProfileMenuOpen = false;
    },
  },
};
</script>

<style></style>
