<template>
  <div>
    <button
      class="transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
      type="button"
      v-on:click="toggleModal()"
    >
      Crear Cuenta
    </button>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <div class="relative w-auto my-6 mx-auto max-w-3xl">
        <!--content-->
        <div
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
        >
          <!--header-->
          <div
            class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
          >
            <h3 class="text-3xl font-semibold">Crear Cuenta</h3>
            <button
              title="Cerrar"
              class="w-8 h-8 rounded-full ml-auto bg-gray-100 hover:bg-gray-200"
              v-on:click="toggleModal()"
            >
              <i class="text-red-500 text-xl icon-cancel-3"></i>
            </button>
          </div>
          <!--body-->
          <div class="relative flex-auto">
            <form class="w-1/2 md:w-full max-w-2xl mt-5">
              <div class="flex flex-wrap items-center justify-evenly mx-4 mb-6">
                <div class="w-full md:w-72">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="account-name"
                  >
                    Cuenta Padre
                  </label>
                  <Multiselect
                    v-model="account"
                    tag-placeholder="Add"
                    placeholder="Cuenta"
                    label="name"
                    track-by="code"
                    :options="objAccount"
                    :multiple="false"
                    :taggable="true"
                    @tag="addTag"
                  ></Multiselect>
                </div>
                <!-- <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="account-code"
                  >
                    Código de Cuenta
                  </label>
                  <input
                    v-model="accountCode"
                    class="appearance-none w-full block bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    title="Código de Cuenta"
                    id="account-code"
                    type="text"
                    placeholder="1.1.1.1.001"
                  />
                </div> -->
                <div class="w-full md:w-1/3 px-3">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="account-name"
                  >
                    Nombre
                  </label>
                  <input
                    v-model="accountName"
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    title="Nombre de Cuenta"
                    id="account-name"
                    type="text"
                    placeholder="e.g. Remodelación"
                  />
                </div>
              </div>
              <!-- <div class="flex flex-wrap -mx-3 mb-2">
                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="account-nature"
                  >
                    Naturaleza
                  </label>
                  <input
                    v-model="accountNature"
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    title="Naturaleza de Cuenta"
                    id="account-nature"
                    type="text"
                    placeholder="C"
                  />
                </div>
              </div> -->
            </form>
          </div>
          <!--footer-->
          <div
            class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
          >
            <button
              class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold text-sm px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              title="Cerrar"
              type="button"
              v-on:click="toggleModal()"
            >
              Cerrar
            </button>
            <button
              class="text-black bg-green-200 border border-solid border-green-400 hover:bg-green-400 hover:text-white font-bold px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              title="Guardar"
              type="button"
              v-on:click="CreateAcc()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
import Axios from "axios";
import Multiselect from "vue-multiselect";
import router from "@/router";
import { mapState } from "vuex";
export default {
  name: "create-account",
  components: {
    Multiselect,
  },
  data() {
    return {
      showModal: false,
      URL: process.env.VUE_APP_URL_ACC,
      accountCode: "",
      accountCode: this.accountCode,
      accountName: "",
      accountGroup: "",
      accountLevel: "",
      accountNature: "D",
      account: "",
      accMutador: this.account,
      objAccount: [],
    };
  },
  computed: {
    ...mapState(["user", "year"]),
  },
  mounted() {
    this.getAccTransaction();
  },
  methods: {
    toggleModal: function () {
      this.showModal = !this.showModal;
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.transaction.push(tag);
    },
    async CreateAcc() {
      let url = `${this.URL}createAccountv2`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`
        },
      };
      console.log(this.account);
      // return
      const params = {
        parent_id: this.account.code,
        company_id: this.user.companyId,
        company: this.user.companyId,
        year: this.year,
        accountCode: this.accountCode,
        accountCode: this.accountCode,
        accountName: this.accountName,
        accountNature: this.accountNature,
      };
      // console.log(params)
      // return
      let res = await Axios.post(url, params, fetchConfig);
      console.log(res);
      if (res.statusText === "OK") {
        this.showModal = !this.showModal;
        this.accountCode = "";
        this.accountCode = "";
        this.accountName = "";
        this.accountGroup = "";
        this.accountLevel = "";
        this.accountNature = "";
        window.location.reload();
        return;
      }
    },
    async getAccTransaction() {
      const URL = `${this.URL}showAllCompanyAccountsFlatMode`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`
        },
      };
      let params = {
        company_id: this.user.companyId,
        company: this.user.companyId,
        year: this.year,
      };

      // try {
      let rsAccount = await Axios.post(URL, params, fetchConfig);
      // console.log(rsAccount)
      this.objAccount = rsAccount.data.data.map((item) => {
        // return { text: item.accountName, value: item.id}
        return {
          name: item.accountCode + " - " + item.accountName,
          code: item.id,
          codeAcc: item.accountCode,
        };
      });
    },
  },
};
</script>
