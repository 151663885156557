<template>
    <div
        class="flex items-start justify-start flex-col md:flex-row p-2 text-sm font-semibold text-gray-100 bg-gradient-to-t from-blue-500 to-blue-400 rounded-lg shadow-md focus:outline-none focus:shadow-outline-purple"
    >
        <img class="h-4 mr-0 md:mr-1" src="../../../public/img/flags/venezuela.webp"/> {{currentTimeVzla}}
        <img class="h-4 ml-0 mr-0 md:ml-2 md:mr-1" src="../../../public/img/flags/us.webp"/> {{currentTimeDallas}}
    </div>
</template>

<script>
import moment from 'moment'
    export default {
        name: 'Banner',
        props: {},
        data() {
            return {
                currentTimeVzla: null,
                currentTimeDallas: null,
            }
        },
        mounted() {
            this.updateCurrentTime()
            setInterval(() => this.updateCurrentTime(), 60000);
        },
        methods: {
            updateCurrentTime() {
                let time = new Date();
                let format = 'MMMM Do YYYY, h:mm a'
                 this.currentTimeVzla = moment(time).utcOffset(-4).format(format)
                 this.currentTimeDallas = moment(time).utcOffset(-6).format(format)
            },
        },
    }
</script>

<style>

</style>