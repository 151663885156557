<template>
	<!-- <div v-if="loaded"
		class="home flex h-screen bg-gray-50 dark:bg-gray-900"
		:class="{ 'overflow-hidden': isSideMenuOpen }"
	> -->
		<!-- <SideBar /> -->
		<!-- <div class="flex flex-col flex-1 w-full">
			<MenuTopbar /> -->

			<!-- <main class="h-full overflow-y-auto"> -->
				<Dashboard />
			<!-- </main> -->
		<!-- </div> -->
	<!-- </div> -->
</template>

<script>
// @ is an alias to /src
// import SideBar from "@/components/sidebar/SideBar.vue";
// import MenuTopbar from "@/components/topbar-content/MenuTopbar.vue";
import Dashboard from "@/components/content/ContentDashboard.vue";
// import router from "@/router";
// import { mapState, mapMutations } from "vuex";

export default {
	name: "Home",
	components: {
		Dashboard,
	},
	data() {
		return {
			isSideMenuOpen: false,
		}
	},
	mounted() {
		// this.checkSession()
		
	},
	computed: {
		// ...mapState(['loaded']),
	},
	methods: {
		// ...mapMutations(['checkSession']),
		toggleSideMenu() {
			this.isSideMenuOpen = !this.isSideMenuOpen;
		},
		
	},
};
</script>
