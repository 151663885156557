<template>
    <li class="relative">
        <button
            class="relative align-middle rounded-md focus:outline-none focus:shadow-outline-purple"
            @click="toggleNotificationsMenu"
            @keydown.escape="closeNotificationsMenu"
            aria-label="Notifications"
            aria-haspopup="true"
        >
            <svg
                class="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
            >
                <path
                    d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"
                ></path>
            </svg>
            <!-- Notification badge -->
            <span
                aria-hidden="true"
                class="absolute top-0 right-0 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full dark:border-gray-800"
            ></span>
        </button>
        <template v-if="isNotificationsMenuOpen">
            <ul
                x-transition:leave="transition ease-in duration-150"
                x-transition:leave-start="opacity-100"
                x-transition:leave-end="opacity-0"
                @click="closeNotificationsMenu"
                @keydown.escape="closeNotificationsMenu"
                class="absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:text-gray-300 dark:border-gray-700 dark:bg-gray-700"
            >
                <li class="flex">
                    <a
                        class="inline-flex items-center justify-between w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                        href="#"
                    >
                        <span>Messages</span>
                        <span
                            class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-600 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-600"
                        >
                            13
                        </span>
                    </a>
                </li>
                <li class="flex">
                    <a
                        class="inline-flex items-center justify-between w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                        href="#"
                    >
                        <span>Sales</span>
                        <span
                            class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-600 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-600"
                        >
                            2
                        </span>
                    </a>
                </li>
                <li class="flex" v-if="PERMISSION">
                    <a v-on:click="showAllPendingApprovalByCompany()"
                        class="inline-flex items-center justify-between w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                        href="#"
                    >
                        <span>Alertas de asientos</span>
                    </a>
                </li>
            </ul>
            
        </template>
        <template>
            <div v-if="showModal" class="fixed h-full top-4 inset-0 flex items-start justify-center overflow-y-scroll z-50">
                <div class="relative my-6 mx-auto w-10/12">
                    <!--content-->
                    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div class="flex items-start justify-between p-3 border-b border-solid border-gray-200 rounded-t">
                        <h3 class="text-xl text-gray-800 font-semibold">
                        Alertas de transacciones pendientes
                        </h3>
                        <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" v-on:click="toggleModal()">
                        <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                        </button>
                    </div>
                    <!--body-->
                    <div class="relative p-2 flex-auto bg-gray-100">
                        <div class="grid grid-cols-1 gap-0  bg-gray-100  dark:bg-gray-800 " >
                            <div v-for="(item, index) in entries" :key="index"  class="py-1 px-2  text-sm  text-gray-700 dark:text-gray-200 text-left" >
                                <div class="w-full p-2 bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                                    Asiento: <span class="mr-6 font-semibold"> {{item.reference_id}} </span> Solicitado por: <span class="mr-6 font-semibold"> {{item.requested_by_name}} </span> Accion: <span class="mr-10 font-semibold"> {{item.action}} </span> 
                                    <button v-if="item.action === 'delete'" v-on:click="deleteEntries(item.reference_id, item.id)" class="py-2 px-2 mr-6 font-medium text-sm leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-md active:bg-blue-600 hover:bg-green-400 focus:outline-none focus:shadow-outline-purple">
                                        <i class="icon-ok mr-2" aria-hidden="true"></i>Aprobar solicitud de Eliminar 
                                    </button>
                                    <button v-if="item.action === 'modify'" v-on:click="modifyEntries(item.reference_id, item.id, item.optionsDecode)" class="py-2 px-2 mr-6 font-medium text-sm leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-md active:bg-blue-600 hover:bg-green-400 focus:outline-none focus:shadow-outline-purple">
                                        <i class="icon-ok mr-2" aria-hidden="true"></i>Aprobar Solicitud de Modificar
                                    </button>
                                    <button v-on:click="rejectRequest(item.id)" class="py-2 px-2 mr-6 font-medium text-sm leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-md active:bg-blue-600 hover:bg-red-400 focus:outline-none focus:shadow-outline-purple">
                                        <i class="icon-cancel mr-2" aria-hidden="true"></i>Rechazar Solicitud
                                    </button>
                                </div>
                                <table class="w-full">
                                    <tbody
                                        class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
                                    >

                                        <tr v-for="(subItem, index) in item.optionsDecode" :key="index" class="text-gray-700 dark:text-gray-400">
                                            
                                            <td class="px-2 py-1 text-xs text-left">
                                                {{subItem.transactionDate}}
                                            </td>
                                            <td class="px-2 py-1 text-xs text-left">
                                                {{subItem.account.accountCode}} - {{subItem.account.accountName}}
                                            </td>
                                            <td class="px-2 py-1 text-xs text-left">
                                                <span>{{subItem.reference_type}}:	{{subItem.reference}} - {{subItem.auxiliary}} </span>
                                            </td>
                                            <td class="px-2 py-1 text-xs text-left">
                                                {{subItem.description}}
                                            </td>								
                                            <td class="px-2 py-1 text-xs text-left">
                                                Debe:{{subItem.debit}}
                                            </td>								
                                            <td class="px-2 py-1 text-xs text-left">
                                                Haber: {{subItem.credit}}
                                            </td>								
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="text-left flex bg-white block-inline w-full p-2">
                                    Comentario: <span class="text-sm ml-3">{{item.comment}}</span>
                                </div>
                                <div v-if="item.action === 'modify'" class="text-left bg-white w-full p-2 mb-4">
                                    <div class="text-md ml-6 font-bold">Asiento Original <br></div>
                                    <div class="pl-6">
                                        <table class="w-full">
                                            <tbody
                                                class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
                                            >
                                                <tr v-for="(subItem, index) in item.originalEntries.account_transactions" :key="index" class="text-gray-700 dark:text-gray-400">
                                                    
                                                    <td class="px-2 py-1 text-xs text-left">
                                                        {{subItem.transactionDate}}
                                                    </td>
                                                    <td class="px-2 py-1 text-xs text-left">
                                                        {{subItem.account.accountCode}} - {{subItem.account.accountName}}
                                                    </td>
                                                    <td class="px-2 py-1 text-xs text-left">
                                                        <span>{{subItem.reference_type}}:	{{subItem.reference}} - {{subItem.auxiliary}} </span>
                                                    </td>
                                                    <td class="px-2 py-1 text-xs text-left">
                                                        {{subItem.description}}
                                                    </td>								
                                                    <td class="px-2 py-1 text-xs text-left">
                                                        Debe:{{subItem.debit}}
                                                    </td>								
                                                    <td class="px-2 py-1 text-xs text-left">
                                                        Haber: {{subItem.credit}}
                                                    </td>								
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                    <!--footer-->
                    <div class="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                        <button class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" title="Cerrar" type="button" v-on:click="toggleModal()">
                            Cerrar
                        </button>
                    </div>
                    </div>
                </div>
            </div>
            <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </template>
    </li>
    
</template>

<script>
import Axios from 'axios'

// import Swal from 'sweetalert2'
import { mapState } from 'vuex'
    export default {
        name: 'MenuNotification',
        props: {},
        data() {
            return {
                isNotificationsMenuOpen: false,
                URL: process.env.VUE_APP_URL_ACC,
                PERMISSION:false,
                entries:[],
                showModal:false,
                entriesOptions:[]
            }
        },
        computed: {
			...mapState(['user', 'year','access_token'])
		},
        mounted() {
            const USERPERMISSIONS = this.user.email;
            const PERMISSIONSDEFAULT = false;
            const PERMISSIONS = {
                    'szchmausser@gmail.com': true,
                    'elisnarmendoza@gmail.com': true,
                    'jorge.hidalgo@riverovisualgroup.com': true,
                    'katiuska.acosta@jdrivero.com': true,
            }
            this.PERMISSION = PERMISSIONS[USERPERMISSIONS] || PERMISSIONSDEFAULT
            // console.log(this.PERMISSION)
		},
        methods: {
            toggleNotificationsMenu() {
                this.isNotificationsMenuOpen = !this.isNotificationsMenuOpen
            },
            closeNotificationsMenu() {
                this.isNotificationsMenuOpen = false
            },
            toggleModal: function(){
                this.showModal = !this.showModal;
                this.entries = []
            },
            async showAllPendingApprovalByCompany() {
				const URL = `${this.URL}show-all-pending-approval-by-company`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json'
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let params = {
					company_id: this.user.companyId,
                    company: this.user.companyId,
                    year: this.year,
				}
				
				try {
                    let rs = await Axios.post(URL, params, fetchConfig)
					
					this.entries = rs.data.data
                    console.log(this.entries)
                    this.showModal = true
                } catch (error) {
                    
                }
					
					
			},
            async deleteEntries(id, approved_id) {

                let opcion = confirm("Esta seguro de realizar esta accion?");
                if (opcion == false) {
                    return;
                }
                const URL = `${this.URL}update-pending-approval-by-company`
                // const URLAPPROVED = `${this.URL}update-pending-approval-by-company`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json'
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let params = { 
					approved_by_name: this.user.fullName,
                    approved_by_id: this.user.userId,
                    id: approved_id,
                    company: this.user.companyId,
                    year: this.year,
				}
				console.log(params)
				try {
					let rs = await Axios.post(URL, params, fetchConfig) //elimina los asientos
                    if (rs.statusText === "OK") {
                    
                        console.log(rs)
                    }
					// this.objAccount = rs.data.data
					// console.log(rs)
                    this.showAllPendingApprovalByCompany()
				} catch (error) {
					console.log(error)
				}
            },
            async modifyEntries(idEntries, approved_id, arrObjeJson) {

                let opcion = confirm("Esta seguro de realizar esta accion?");
                if (opcion == false) {
                    return;
                }
                const URL = `${this.URL}storeTransaction`
                
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json'
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let params = { 
					"accounting_entry_id": idEntries,
                    "is_initial_balance": 0,
                    "approval_id": approved_id,
                    "approved_by_name": this.user.fullName,
                    "approved_by_id": this.user.userId,
                    "transactions": arrObjeJson,
                    "company": this.user.companyId,
                    "year": this.year,

				}
				try {

                    let rs = await Axios.post(URL, params, fetchConfig) //insertar los asientos modificados
                    console.log(rs)
                    if (rs.statusText === "OK") {
                        console.log(rs.statusText)
                        this.showAllPendingApprovalByCompany()
                    }
                    
				} catch (error) {
					console.log(error)
				}
            },
            async rejectRequest(id){
                const URL = `${this.URL}update-pending-approval-by-company`
                const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json'
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
                let params = { 
					reject_by_name: this.user.fullName,
                    reject_by_id: this.user.userId,
                    id: id,
                    company: this.user.companyId,
                    year: this.year,
				}
                console.log(params)
                try {
                    let rs1 = await Axios.post(URL, params, fetchConfig)
                    this.showAllPendingApprovalByCompany()
                        console.log(rs1)
                } catch (error) {
                    
                }
            }
        },
        
    }
</script>

<style>

</style>