<template>
  <div id="app" >
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <div v-if="loaded"
    class="home flex h-screen bg-gray-50 dark:bg-gray-900"
    :class="{ 'overflow-hidden': isSideMenuOpen }"
    >
      <SideBar />
      <div class="flex flex-col flex-1 w-full">
        <MenuTopbar />
        <main class="h-full overflow-y-auto">
          <router-view/>
        </main>
      </div>
    </div>
    <div v-else>
      <Login />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import SideBar from "@/components/sidebar/SideBar.vue";
import MenuTopbar from "@/components/topbar-content/MenuTopbar.vue";
import Login from "@/views/Login.vue";

export default {
  components: {
		SideBar,
    MenuTopbar,
    Login,
	},
  data() {
		return {
			isSideMenuOpen: false,
		}
	},
	mounted() {
		this.checkSession()
		
	},
	computed: {
		...mapState(['loaded']),
	},
	methods: {
		...mapMutations(['checkSession']),
		toggleSideMenu() {
			// this.isSideMenuOpen = !this.isSideMenuOpen;
		},
		
	},
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
