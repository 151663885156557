<template>
  <header class="z-10 py-4 bg-white shadow-sm dark:bg-gray-800">
    <div
      class="container flex items-center justify-between h-full px-6 mx-auto text-blue-500 dark:text-purple-300"
    >
      <!-- Mobile hamburger -->
      <button
        class="p-1 mr-5 -ml-1 rounded-md md:hidden focus:outline-none focus:shadow-outline-purple"
        @click="toggleSideMenu"
        aria-label="Menu"
      >
        <svg
          class="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <!-- Search input -->
      <div class="flex w-1/2 items-start justify-start">
        <Banner />
      </div>
      <div class="flex flex-col md:flex-row justify-end flex-1 lg:mr-6">
        <div v-if="isReady">
          <div v-if="changeCompany === 'Y'" class="w-full text-sm flex flex-col md:flex-row justify-end">
            <i class="icon-location"></i>
            <select
              v-model="selectCountry"
              class="md:w-1/4 mr-2 lg:mr-2 transition ease-in-out duration-200 text-gray-700 bg-white bg-clip-padding bg-no-repeat border-2 rounded border-gray-300 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            >
              <option
                v-for="(country, index) in oCountry"
                :key="index"
                :value="country.id"
              >
                {{ country.country }}
              </option>
            </select>
            <i class="icon-building"></i>
            <select
              v-model="selectCompany"
              @change="toAssignCompany($event)"
              class="md:w-3/4 mr-2 lg:mr-0 transition ease-in-out duration-200 text-gray-700 bg-white bg-clip-padding bg-no-repeat border-2 rounded border-gray-300 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            >
              <!-- <option v-for="(company, index) in oCompany" :key="index" class="selectCountry" :attrCountry="[company.color, company.companyAddress, company.companyName, company.companyNumber, company.logo]" :value="{id: company.companyId}">{{company.companyName}}</option> -->
              <option
                v-for="(company, index) in oCompany"
                :key="index"
                :value="company.companyId"
              >
                {{ company.companyName }}
              </option>
            </select>
          </div>
          <div v-else class="w-full flex flex-wrap">
            <ul class="mr-6 lg:mr-14">
              <li
                v-for="(country, index) in oCountry"
                :key="index"
                :value="country.id"
              >
                <p v-if="country.id === selectCountry">{{ country.country }}</p>
              </li>
            </ul>
            <ul>
              <li
                v-for="(company, index) in oCompany"
                :key="index"
                :value="company.companyId"
              >
                <p v-if="company.companyId === selectCompany">
                  {{ company.companyName }}
                </p>
              </li>
            </ul>
            <!-- <ul>
              <li
                v-for="(year, index) in oYears"
                :key="index"
                :value="year"
              >
                <p v-if="company.companyId === selectCompany">
                  {{ company.companyName }}
                </p>
              </li>
            </ul> -->
          </div>
          <div class="w-full text-sm flex flex-col md:flex-row justify-end">
            <i class="icon-building"></i>
            <select
              v-model="yearSelected"
              @change="changeYearCompanys($event)"
              class="md:w-1/4 transition ease-in-out duration-200 text-gray-700 bg-white bg-clip-padding bg-no-repeat border-2 rounded border-gray-300 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            >
              <!-- <option v-for="(company, index) in oCompany" :key="index" class="selectCountry" :attrCountry="[company.color, company.companyAddress, company.companyName, company.companyNumber, company.logo]" :value="{id: company.companyId}">{{company.companyName}}</option> -->
              <option
                v-for="(year, index) in oYears"
                :key="index"
                :value="year.current_year"
              >
                {{ year.current_year }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <ul class="flex items-center flex-shrink-0 space-x-6">
        <!-- Theme toggler -->
        <MenuToggleTheme />
        <!-- Notifications menu -->
        <MenuNotification />
        <!-- Profile menu -->
        <MenuProfile />
      </ul>
    </div>
  </header>
</template>

<script>
import Banner from "@/components/util/Banner.vue";
import MenuProfile from "@/components/topbar-content/MenuProfile.vue";
import MenuNotification from "@/components/topbar-content/MenuNotification.vue";
import MenuToggleTheme from "@/components/topbar-content/MenuToggleTheme.vue";
import Axios from "axios";
import router from "@/router";
import { mapState, mapMutations } from "vuex";

export default {
  name: "MenuTopbar",
  components: {
    Banner,
    MenuProfile,
    MenuNotification,
    MenuToggleTheme,
  },
  props: {},
  data() {
    return {
      isSideMenuOpen: false,
      oCountry: [],
      oCompany: [],
      isReady: false,
      selectCountry: 0,
      selectCompany: 0,
      changeCompany: "N",
      oYears: [],
      yearSelected: 0,
    };
  },

  mounted() {
    this.checkSession();
    this.getContryCompany();
    this.showAllCompanyConfigs();
    this.yearSelected = this.year
  },
  computed: {
    ...mapState(["access_token", "user" , "year"]),
  },
  methods: {
    ...mapMutations(["changeContryAndCompany", "checkSession", "changeYearCompany"]),
    async getContryCompany() {
      const URL = `${process.env.VUE_APP_URL}country-company`;

      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token.replace(/['"]+/g, "")}`,
        },
      };

      try {
        let res = await Axios.get(URL, fetchConfig);
        // console.log(res)
        let country = res.data.data.oCompany.map((item) => {
          return {
            id: item.id,
            country: item.country,
            companys: item.companys,
          };
        });
        console.log(country);
        this.oCountry = country;

        // asignar el pais por defecto para el usuario
        this.selectCountry = this.user.countryId;

        // asignar si el usuario tiene permiso para cambiar de compañia
        this.changeCompany = this.user.changeCompany;
        // console.log(this.user)

        this.isReady = true;
      } catch (error) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        localStorage.removeItem("year");
        localStorage.removeItem("accTemp");
        localStorage.removeItem("accTransResul");
        router.push({ name: "Login", path: "/login" });
      }
    },
    async showAllCompanyConfigs(){
      const URL = `${process.env.VUE_APP_URL_ACC}show-all-company-configs`;

      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization: `Bearer ${this.access_token.replace(/['"]+/g, "")}`,
        },
      };

      try {
        let params = {
          company_id: this.user.companyId,
        }
        let res = await Axios.post(URL, params, fetchConfig);
        this.oYears = res.data.data
        // console.log('menu top barres')
        console.log(res)
      }catch(error){
        console.log(error)
      }
    },
    changeYearCompanys(){
      this.changeYearCompany([this.yearSelected])
    },
    toAssignCompany(event) {
      console.log(this.oCountry);
      let country;
      let color;
      let companyAddress;
      let companyName;
      let companyNumber;
      let logo;

      this.oCountry.forEach((item) => {
        if (item.id === this.selectCountry) {
          country = item.country;
          item.companys.forEach((element) => {
            if (element.companyId === this.selectCompany) {
              color = element.color;
              companyAddress = element.companyAddress;
              companyName = element.companyName;
              companyNumber = element.companyNumber;
              logo = element.logo;
            }
          });
        }
      });

      // console.log(country)
      // console.log(color)
      // console.log(companyAddress)
      // console.log(companyName)
      // console.log(companyNumber)
      // console.log(logo)
      // return
      this.changeContryAndCompany([
        this.selectCountry,
        this.selectCompany,
        country,
        color,
        companyAddress,
        companyName,
        companyNumber,
        logo,
      ]);
    },
    toggleSideMenu() {
      this.isSideMenuOpen = !this.isSideMenuOpen;
    },
  },
  watch: {
    selectCountry: function (params) {
      // console.log(params)
      const companys = this.oCountry.find((e) => e.id === params);
      // console.log('companys');
      // console.log(companys);
      this.oCompany = companys.companys;
      // console.log(this.oCompany);
      // asignar la compañia por defecto del usuario
      this.selectCompany = this.user.companyId;
    },
  },
};
</script>

<style></style>
